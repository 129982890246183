'use strict';

module.exports = function parseObject(value) {
  if (!value) {
    return [];
  }

  const matches = value.match(/^([^~]*)~([^~]*)~([^~]*)~(.*)$/);

  if (!matches) {
    return [];
  }

  return [
    matches[1],
    matches[2],
    matches[3],
    matches[4]
  ];
};
