'use strict';

/**
 * All supported metrics.
 * Associates the actual Elasticsearch aggregation and human-readable label to each metric.
 *
 * @type {{string: [aggregation: string, label: string]}}
 */
module.exports = {
  'Latest of': ['latest', 'last'],
  'Count of': ['cardinality', 'count'],
  'Average of': ['avg', 'avg'],
  'Max of': ['max', 'max'],
  'Min of': ['min', 'min'],
  'Sum of': ['sum', 'sum'],
  '.95 of': ['p95', 'p95'],
  '.99 of': ['p99', 'p99'],
  'Sum of maxes of': ['sumOf:max', 'sum(max)'],
  'Sum of mins of': ['sumOf:min', 'sum(min)'],
  custom: ['custom', 'custom']
};
